import React from "react";
import "./LandingPage.css";

function LandingPage() {
  return (
    <div className="container-fluid">
      <div className="row topSection">
        {/* <div className="column  col-md-6">
          <h1 className="goldloxeTitle">Goldloxe </h1>
        </div> */}
        {/* <div className="column col-md-6"> */}
        <div className="goldloxeBio">
          <div className="goldloxeBioText">
            From the block to the blockchain - The Just Right! project by NYC
            street artist Goldloxe is spreading a global message of gratitude
            and perspective in a time of great change.
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
