import "./styles.css";
import GoldloxeTitle from "./goldloxeTitle.gif";
import Background from "./cosmos.jpg";
import GBLSTS from "./GS_Cubo_Blk.png";
import Coin from "./JustRight.gif";
import LandingPage from "./LandingPage/LandingPage";
import SocialIcons from "./SocialIcons/SocialIcons";

export default function App() {
  const style = {
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundClip: "none",
    backgroundRepeat: "repeat-y",
    backgroundAttachment: "scroll",
    overflow: "hidden",
    height: "100%", // Cover the full viewport height
    margin: 0, // Reset margin to eliminate any default margin
    padding: 0, // Reset padding
  };
  return (
    <div className="App" style={style}>
      <div className="Title">
        <img src={GoldloxeTitle} className="goldloxeTitle" />
        <h4 className="titleCaption"> NYC Street Artist </h4>
        <h4> reminding us we're all cosmic lottery winners</h4>
      </div>

      <div className="socialIcons">
        <SocialIcons />
      </div>

      <div className="justRightCoin">
        <img className="CoinImage" src={Coin} alt="Just Right! and Coming Soon spinning coin with example artworks" />
      </div>

      <div className="gblsts">
      <a href="https://www.gblsts.com">
        <h4> Generative art collection in collaboration with</h4>
        <img src={GBLSTS} className="gblstsLogo" alt="gblsts logo and link to"/>
         </a>
      </div>
    </div>
  );
}
