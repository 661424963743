import React from "react";
import "./SocialIcons.css";
import Insta from "./images/instagram-black-transparent.png";
import X from "./images/X-black-transparent.png";
import Ln from "./images/linkedIn-black-transparent.png";
import TT from "./images/black-tiktok-logo.png";

function SocialIcons() {
  return (
    <div className="socialContainer">
      <div className="icon instagramIcon">
        <a href="https://www.instagram.com/goldloxe">
          <img src={Insta} alt="instagram logo with a hyperlink to the Goldloxe Instagram page"/>
        </a>
      </div>
      <div className="icon xIcon">
        <a href="https://twitter.com/goldloxe">
          <img src={X} alt="X logo with a hyperlink to the Goldloxe X/Twitter page"/>
        </a>
      </div>
      <div className="icon linkedInIcon">
        <a href="https://www.linkedin.com/in/landy-slattery-03bb2713?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
          <img src={Ln} alt="linkedIn logo with a hyperlink to the Goldloxe linkedIn page" />{" "}
        </a>
      </div>
      <div className="icon tiktokIcon">
        <a href="https://www.tiktok.com/@goldloxe">
          <img src={TT} alt="TikTok logo with a hyperlink to the Goldloxe TikTok page" />{" "}
        </a>
      </div>
    </div>
  );
}
export default SocialIcons;
